import "./App.css";
import { ContextProvider } from "./context";
import { LoginPage } from "./pages/loginPage";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/homePage";
import { PredictionsPage } from "./pages/predictionsPage";
import { RacePredictionsPage } from "./pages/racePredictionsPage";
import { DriverPredictionsPage } from "./pages/driverPredictionsPage";
import { BoldPredictionsPage } from "./pages/boldPredictionsPage";
import { WagersPage } from "./pages/wagersPage";
import { DriverRankingsPage } from "./pages/driverRankingsPage";
import { TeamRankingsPage } from "./pages/teamRankingsPage";
import { GroupPage } from "./pages/groupPage";
import { GroupDetailPage } from "./pages/groupDetailPage";
import { TeamsPage } from "./pages/teamsPage";

function App() {
  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "black",
        height: "calc(var(--vh, 1vh) * 100)",
      }}
    >
      <ContextProvider>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />

          <Route path="/predictions" element={<PredictionsPage />} />
          <Route path="/predictions/team-rankings" element={<TeamRankingsPage />} />
          <Route path="/predictions/driver-rankings" element={<DriverRankingsPage />} />
          <Route path="/predictions/race-predictions" element={<RacePredictionsPage />} />
          <Route path="/predictions/driver-predictions" element={<DriverPredictionsPage />} />
          <Route path="/predictions/bold-predictions" element={<BoldPredictionsPage />} />
          <Route path="/predictions/wagers" element={<WagersPage />} />

          <Route path="/group" element={<GroupPage />} />
          <Route path="/group/detail" element={<GroupDetailPage />} />

          <Route path="/team" element={<TeamsPage />} />
        </Routes>
      </ContextProvider>
    </div>
  );
}

export default App;
