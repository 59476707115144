export const Drivers = [
  {
    name: "Lando Norris",
    number: 4,
    team: "McLaren",
  },
  {
    name: "Oscar Piastri",
    number: 81,
    team: "McLaren",
  },
  {
    name: "Charles Leclerc",
    number: 16,
    team: "Ferrari",
  },
  {
    name: "Lewis Hamilton",
    number: 44,
    team: "Ferrari",
  },
  {
    name: "Max Verstappen",
    number: 1,
    team: "Red Bull Racing",
  },
  {
    name: "Liam Lawson",
    number: 30,
    team: "Red Bull Racing",
    rookie: true,
  },
  {
    name: "George Russell",
    number: 63,
    team: "Mercedes",
  },
  {
    name: "Andrea Kimi Antonelli",
    number: 12,
    team: "Mercedes",
    rookie: true,
  },
  {
    name: "Lance Stroll",
    number: 18,
    team: "Aston Martin",
  },
  {
    name: "Fernando Alonso",
    number: 14,
    team: "Aston Martin",
  },
  {
    name: "Jack Doohan",
    number: 7,
    team: "Alpine",
    rookie: true,
  },
  {
    name: "Pierre Gasly",
    number: 10,
    team: "Alpine",
  },
  {
    name: "Isack Hadjar",
    number: 6,
    team: "Racing Bulls",
    rookie: true,
  },
  {
    name: "Yuki Tsunoda",
    number: 22,
    team: "Racing Bulls",
  },
  {
    name: "Estaban Ocon",
    number: 31,
    team: "Haas",
  },
  {
    name: "Oliver Bearmean",
    number: 87,
    team: "Haas",
    rookie: true,
  },
  {
    name: "Alexander Albon",
    number: 23,
    team: "Williams",
  },
  {
    name: "Carlos Sainz",
    number: 55,
    team: "Williams",
  },
  {
    name: "Nico Hulkenberg",
    number: 27,
    team: "Kick Sauber",
  },
  {
    name: "Gabriel Bortoleto",
    number: 5,
    team: "Kick Sauber",
    rookie: true,
  },
];

export const Rookies = [
  ...Drivers.filter((driver) => driver?.rookie),
  { name: "Franco Colapinto", number: 43, team: "Alpine", rookie: true },
];
