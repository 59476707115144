import React, { useContext } from "react";
import { DraggableList } from "../components/draggableList";
import { Nav } from "../components/nav";
import { PageContainer } from "../components/pageContainer";
import { TextContainer } from "../components/textContainer";
import { F1Context } from "../context";
import { Teams } from "../utils/teams";
import { SaveButton } from "../components/saveButton";

export const TeamRankingsPage = () => {
  const [context, updateContext] = useContext(F1Context);
  return (
    <PageContainer>
      <div style={{ width: "340px", textAlign: "left", marginBottom: "10px" }}>
        Team Rankings
      </div>
      <TextContainer>Set the Team Finishing Order</TextContainer>
      <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              marginTop: "10px",
              marginRight: "10px",
            }}
          >
            {Teams.map((value, index) => (
              <div
                key={index}
                style={{ height: "25.33px", marginBottom: "16px" }}
              >
                {index + 1}
              </div>
            ))}
          </div>
          <DraggableList
            items={context?.teamRankings}
            setItems={(newOrder) => {
              updateContext({
                type: "update-team-rankings",
                rankings: newOrder,
              });
            }}
          />
        </div>
        <SaveButton />
      </div>
      <Nav />
    </PageContainer>
  );
};
