import { colors } from "./colors";
import { Drivers } from "./drivers";
import RedBullLogo from "../icons/team-logos/RedBullRacing.png";
import FerrariLogo from "../icons/team-logos/Ferrari.png";
import MercedesLogo from "../icons/team-logos/Mercedes.png";
import AlpineLogo from "../icons/team-logos/Alpine.png";
import McLarenLogo from "../icons/team-logos/McLaren.png";
import KickSauberLogo from "../icons/team-logos/KickSauber.png"
import AstonMartinLogo from "../icons/team-logos/AstonMartin.png";
import HaasLogo from "../icons/team-logos/Haas.png";
import RacingBullsLogo from "../icons/team-logos/RacingBulls.png"
import WilliamsLogo from "../icons/team-logos/Williams.png";

export const Teams = [
  {
    name: "McLaren",
    drivers: [...Drivers.filter(driver => driver.team === "McLaren")],
    color: colors.mcLarenOrange,
    logo: McLarenLogo,
  },
  {
    name: "Ferrari",
    drivers: [...Drivers.filter(driver => driver.team === "Ferrari")],
    color: colors.ferrariRed,
    logo: FerrariLogo,
  },
  {
    name: "Red Bull Racing",
    drivers: [...Drivers.filter(driver => driver.team === "Red Bull Racing")],
    color: colors.redBullBlue,
    logo: RedBullLogo,
  },
  {
    name: "Mercedes",
    drivers: [...Drivers.filter(driver => driver.team === "Mercedes")],
    color: colors.mercedesGreen,
    logo: MercedesLogo,
  },
  {
    name: "Aston Martin",
    drivers: [...Drivers.filter(driver => driver.team === "Aston Martin")],
    color: colors.astonMartinGreen,
    logo: AstonMartinLogo,
  },
  {
    name: "Alpine",
    drivers: [...Drivers.filter(driver => driver.team === "Alpine")],
    color: colors.alpineBlue,
    logo: AlpineLogo,
  },
  {
    name: "Racing Bulls",
    drivers: [...Drivers.filter(driver => driver.team === "Racing Bulls")],
    color: colors.racingBullsBlue,
    logo: RacingBullsLogo,
  },
  {
    name: "Haas",
    drivers: [...Drivers.filter(driver => driver.team === "Haas")],
    color: colors.haasGray,
    logo: HaasLogo,
  },
  {
    name: "Williams",
    drivers: [...Drivers.filter(driver => driver.team === "Williams")],
    color: colors.williamsBlue,
    logo: WilliamsLogo,
  },
  {
    name: "Kick Sauber",
    drivers: [...Drivers.filter(driver => driver.team === "Kick Sauber")],
    color: colors.kickSauberGreen,
    logo: KickSauberLogo,
  },
];
