export const pushToDynamo = async (context) => {
    const {user, groupPredictions, fetchFromDynamoStatus, detailViewKey, ...data} = context;
    await fetch(
        "https://njz4z9rn49.execute-api.us-east-1.amazonaws.com/prediction",
        {
            method: 'POST',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                groupId: "1",
                data: JSON.stringify(data),
                predictionUser: user,
                predictionYear: 2025
            })
        }
    )
}