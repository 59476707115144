import { Reorder } from "framer-motion";
import { colors } from "../utils/colors";
import { ReactComponent as DraggableIcon } from "../icons/dragHandleVertical.svg";

export function DraggableList({ items, setItems }) {
  return (
    <Reorder.Group
      axis="y"
      values={items}
      onReorder={setItems}
      style={{
        listStyleType: "none",
        marginBlockStart: "0px",
        marginBlockEnd: "0px",
        paddingInlineStart: "0px",
      }}
    >
      {items.map((item) => (
        <Reorder.Item
          key={item}
          value={item}
          style={{
            display: "flex",
            alignItems: "center",
            width: "308px",
            height: "24px",
            backgroundColor: colors.white,
            color: colors.black,
            fontSize: "12px",
            margin: "16px 0px",
            border: `1px solid ${colors.primaryRedDark}`,
            borderRadius: `2px`,
            textAlign: "start",
          }}
        >
          <DraggableIcon
            style={{
              minHeight: "36px",
              width: "12px",
              marginLeft: "6px",
              marginRight: "6px",
            }}
          />
          {item}
        </Reorder.Item>
      ))}
    </Reorder.Group>
  );
}
